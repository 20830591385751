import { useState, useEffect, useContext } from "react";
import {
  TextField,
  Button,
  Container,
  makeStyles,
  FormControl,
  InputLabel,
  InputAdornment,
  Input,
  MenuItem,
  Select,
  Modal,
  Typography,
  Grid,
  IconButton,
  Box,
  FormHelperText,
} from "@material-ui/core";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useHistory, useLocation } from "react-router-dom";
import { PatientpageContext } from "../context/GlobalContext";
import CloseIcon from "@material-ui/icons/Close";
import { ServiceCall } from "../utility/service-call";
import RegistrationCouncil from "../pages/Doctor/Regitration-Council";
import PrescriptionNotes from "../pages/Doctor/PrescriptionNotes";
import PrescriptionEditor from "./PrescriptionEditor";
import { Ls } from "../utility/utility";
import { constants } from "../utility/constants";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import PageContentLayout from "./PageContentLayout";
import ImageAvatar from "./ImageAvatar";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import UploadIcon from '../static/change-camera-basic-svgrepo-com.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // width: '93%',
    marginTop: theme.spacing(4),
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
    "& .MuiFormControl-root": {
      margin: theme.spacing(1),
    },
    [theme.breakpoints.up("md")]: {
      // width: '60%',
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  nameContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "103%",
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
  },
  nameField: {
    width: "50%",
    [theme.breakpoints.up("md")]: {
      width: "30%",
    },
  },
  salutationFormControl: {
    width: "27%",
    [theme.breakpoints.up("md")]: {
      width: "20%",
    },
  },
  gender: {
    width: "30%",
    [theme.breakpoints.up("md")]: {
      width: "20%",
    },
  },
  button: {
    margin: theme.spacing(2, 0),
    backgroundColor: "#199a8e",
    "&:hover": {
      backgroundColor: "#199a8e",
    },
    [theme.breakpoints.up("md")]: {
      // width: '30%',
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
}));

const BasicDetails = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const doctorContext = useContext(PatientpageContext);
  const [loading, setLoading] = useState(false);
  const [saved, setSaved] = useState(false); // State to track whether form has been saved
  const [menuOpen, setMenuOpen] = useState(false);
  const [apiError, setApiError] = useState("");

  const [secondaryLanguages, setSecondaryLanguages] = useState({});

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    title: "Dr",
    gender: "",
    profile_title: "",
    profile_details: "",
    years_of_experience: "",
    languages_list: [],
    registrations_list: [
      {
        registration_id: "",
        registration_council: "",
        valid_from: "",
      },
    ],
    memberships_list: [
      {
        member_of: "",
        member_from: "",
        valid_from: "",
      },
    ],
    practice_areas_list: [],
  });

  const [openModal, setOpenModal] = useState(false);
  const [openMemModal, setOpenMemModal] = useState(false);
  const [specializations_list, setSelectedSpecializations] = useState([]);
  const [specialData, setSpecialData] = useState([]);
  const [textFieldSpecializations, setTextFieldSpecializations] = useState("");
  const [disableView, setDisableView] = useState(true);
  const [practiceAreas, setPracticeAreas] = useState([]);
  const [filledFields, setFilledFields] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [imageKey, setImageKey] = useState(0);

  const setState = doctorContext.setState;

  const [receiveNotes, setReceiveNotes] = useState({
    symptoms_list: [],
  });
  const [pData, setPData] = useState({
    symptoms_list: [],
  });

  const numbers = Array.from({ length: 100 }, (_, i) => i + 1);

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await ServiceCall.getv2(
          "/v2/doctor/myprofile/basic/details/",
          `${constants.hospitalId("hosp_id")}`,
          {
            doc_secret: Ls.get("docToken"),
            doc_key: Ls.get("docKey"),
          }
        );

        // Parse the response data
        const { data } = response;
        if (data) {
          // Map the data to match the formData structure
          const mappedData = {
            first_name: data.first_name || "",
            last_name: data.last_name || "",
            gender: data.gender || "",
            title: data.title || "",
            profile_title: data.profile_title || "", // Since profile_title is not provided in the response, leave it empty
            profile_details: data.profile_details || "", // Use profile_details from the response
            years_of_experience: data.years_of_experience || "", // Use years_of_experience from the response
            languages_list: data.languages || [], // Use languages from the response
            specialisations_list: data.specialisations || [], // Use specialisations from the response
            registrations_list: data.registrations || [], // Use registrations from the response
            memberships_list: data.memberships || [], // Use memberships from the response
            practice_areas_list: data.practice_areas || [], // Use practice_areas from the response
          };

          // Set the mapped data into the state
          setFormData(mappedData);
          setSelectedSpecializations(data.specialisations || []);
          setPData(prevPData => ({
            ...prevPData,
            practice_areas_list: data.practice_areas || []
          }));
  

        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
    
  }, []);

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();

    const body = {
      first_name: formData?.first_name,
      last_name: formData?.last_name,
      title: formData?.title,
      gender: formData?.gender,
      specialisations_list: [...specializations_list, textFieldSpecializations],
      profile_title: formData?.profile_title,
      profile_details: formData?.profile_details,
      years_of_experience: formData?.years_of_experience,
      languages_list: formData?.languages_list,
      registrations_list: [
        {
          registration_id: formData?.registrations_list[0]?.registration_id,
          registration_council:
            formData?.registrations_list[0]?.registration_council,
          valid_from: formData?.registrations_list[0]?.valid_from,
        },
      ],
      memberships_list: [
        {
          member_of: formData?.memberships_list[0]?.member_of,
          member_from: formData?.memberships_list[0]?.member_from,
          valid_from: formData?.memberships_list[0]?.valid_from,
        },
      ],
      practice_areas_list: formData?.practice_areas_list,
    };

    try {
      
      const Handlesub = await ServiceCall.putv2(
        "/v2/doctor/myprofile/basic/details/",
        `${constants.hospitalId("hosp_id")}`,
        body, // Use the updated body with specializations_list included
        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      );

      if (Handlesub) {
        setLoading(false);
        setState({
          msg: Handlesub?.data.message || "",
          type: "success",
          timer: null,
          vertical: "top",
          horizontal: "center",
        });
      }
      await new Promise((resolve) => setTimeout(resolve, 1000)); // Simulate API call
      setSaved(true); // Update state to indicate form has been saved
    } catch (err) {
      if (err.response) {
        setApiError(err.response.data.message);
        setState({
          msg: err?.response?.data?.message || "",
          type: "error",
          timer: null,
          vertical: "top",
          horizontal: "center",
        });
      } else if (err.request) {
        setApiError("Failed request " + err);
      } else {
        setApiError("Failed in general " + err);
      }
    }
  };

  const handlle = () => {
    history.push({
      pathname: "/my-profile",
      search: history.location.search,
    });
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleOpenRegistrationModal = () => {
    setOpenModal(true);
  };

  const handlemem = () => {
    setOpenMemModal(true);
  };

  const handleCloseMo = () => {
    setOpenMemModal(false);
  };

  const handleChang = (event) => {
    const { value } = event.target;
    setSelectedSpecializations(value); // Update selected specializations
  };

  useEffect(() => {
    const handlefetchSecondaryLanguage = async () => {
      const { data, errors } = await doctorContext.getv2Call(
        "/v2/doctor/prescription/list/secondary/langs",
        "",
        "",
        true
      );

      if (data?.data) {
        setSecondaryLanguages(data?.data);
      }
    };
    handlefetchSecondaryLanguage();
  }, []);

  useEffect(() => {
    const Getspecialization = async () => {
      try {
        const specialization = await ServiceCall.getnewv3(
          "/hospital/list/medical/specializations"
        );
        if (specialization) {
          setSpecialData(specialization.data.records);
        }
      } catch (err) {
        console.error("Error fetching specializations:", err);
      }
    };
    Getspecialization();
  }, []);

  const handleTextFieldSpecializationsChange = (event) => {
    setTextFieldSpecializations(event.target.value);
  };
  const handleRegistrationChange = (index, event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      registrations_list: prevFormData.registrations_list.map(
        (registration, i) => {
          if (i === index) {
            return {
              ...registration,
              [name]: value,
            };
          }
          return registration;
        }
      ),
    }));
    // Update filledFields when a field is filled
    // setFilledFields(prevFilledFields => {
    //   const newFilledFields = [...prevFilledFields];
    //   newFilledFields[index] = true;
    //   return newFilledFields;
    // });
  };

  const handleRegistrationChang = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      registrations_list: [
        {
          ...prevFormData.registrations_list[0],
          [name]: value,
        },
      ],
    }));
  };

  const handleMembershipChang = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      memberships_list: [
        {
          ...prevFormData.memberships_list[0],
          [name]: value,
        },
      ],
    }));
  };

  const handleMembershipChange = (index, event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      memberships_list: prevFormData.memberships_list.map((memberships, i) => {
        if (i === index) {
          return {
            ...memberships,
            [name]: value,
          };
        }
        return memberships;
      }),
    }));
  };

  function checkDisable() {
    const hasValues = Object.values(pData).some(arr => arr.length > 0);
    setDisableView(!hasValues);
  }
  const pdataObj = {
    symptoms_list: "symptoms",
  };

  function updatePData(value, type) {
    setPData(prevPData => ({
      ...prevPData,
      [type]: value
    }));
    setFormData(prevFormData => ({
      ...prevFormData,
      practice_areas_list: value
    }));
    checkDisable();
  }
  const handleExperienceSubmit = (event) => {
    event.preventDefault(); // Prevent form submission
    handleCloseMo();
  };
  const handleEducationSubmit = (event) => {
    event.preventDefault(); // Prevent form submission
    handleCloseModal();
  };
  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  const handleAddServic = () => {
    const newRegistrationLength = formData.registrations_list.length + 1;

    setFormData((prevFormData) => ({
      ...prevFormData,
      registrations_list: [
        ...prevFormData.registrations_list,
        {
          registration_id: "",
          registration_council: "", // Initialize degree field with an empty string
          valid_from: "",
        },
      ],
    }));

    setFilledFields((prevFilledFields) => [
      ...prevFilledFields,
      newRegistrationLength,
    ]);
  };

  const handleAddService = () => {
    const newRegistrationLength = formData.memberships_list.length + 1;

    setFormData((prevFormData) => ({
      ...prevFormData,
      memberships_list: [
        ...prevFormData.memberships_list,
        {
          member_of: "",
          member_from: "",
          valid_from: "",
        },
      ],
    }));
    setFilledFields((prevFilledFields) => [
      ...prevFilledFields,
      newRegistrationLength,
    ]);
  };
  const handleRemoveExperince = (indexToRemove) => {
    setFormData((prevState) => ({
      ...prevState,
      registrations_list: prevState.registrations_list.filter(
        (_, index) => index !== indexToRemove
      ),
    }));
    // Update filledFields when a field is removed
    setFilledFields((prevFilledFields) => {
      const newFilledFields = [...prevFilledFields];
      newFilledFields.splice(indexToRemove, 1);
      return newFilledFields;
    });
  };

  const handleRemoveMember = (indexToRemove) => {
    setFormData((prevState) => ({
      ...prevState,
      memberships_list: prevState.memberships_list.filter(
        (_, index) => index !== indexToRemove
      ),
    }));
    setFilledFields((prevFilledFields) => {
      const newFilledFields = [...prevFilledFields];
      newFilledFields.splice(indexToRemove, 1);
      return newFilledFields;
    });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    handleUpload(file); // Pass the selectedFile as an argument
  };

  const handleUpload = async (file) => {
    if (file) {
      const formData = new FormData();
      formData.append("image", file);
      
      try {
        const upload = await ServiceCall.putv2(
          "/v2/update/doctor/pic/",
          `${constants.hospitalId("hosp_id")}`,
          formData,
          {
            doc_secret: Ls.get("docToken"),
            doc_key: Ls.get("docKey"),
          }
        );
        
        if (upload) {
          // Increment the key to force a re-render of the ImageAvatar component
          setImageKey(prevKey => prevKey + 1);
        }
        
        console.log("Upload response:", upload);
  
        // Set a timeout to refresh the page after 2 seconds
        setTimeout(() => {
          window.location.reload();
        }, 100);
  
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }
  };
  

  return (
    <>
      <div style={{background: "#e8f3f1"}}>
        <div className="avtar" style={{marginTop: "1.5rem"}}>
          <ImageAvatar
            async
            imagePath={`/v2/get/doctor/pic/`}
            name={`firstname lastname`}
            cardClass="header__avatar"
          />
          <label htmlFor="fileInput">
            <img
              style={{
                cursor: "pointer",
                maxHeight: "28px",
                marginLeft: "-64px",
                marginBottom: "-48px",
                backgroundColor: "white",
                borderRadius: "50%"
              }}
              src={UploadIcon}
            />
          </label>
          {/* File input */}
          <input
            type="file"
            id="fileInput"
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
        </div>
        <Container component="main" maxWidth="xs">
          <form className={classes.root} onSubmit={handleSubmit}>
            <p
              style={{
                // border: "2px solid #d8d8d8",
                // borderWidth: "19px",
                color: "#50555c",
                // backgroundColor: "#d8d8d8",
                // width: "446px",
                textAlign: "center",
                width: "100%",
                fontSize: "0.875rem",
                fontWeight: "600",
                marginBottom: "2rem",
              }}
            >
              Enter the basic profile details and click Save
            </p>
            <div className={classes.nameContainer}>
              <FormControl
                variant="outlined"
                className={classes.salutationFormControl}
                required
                style={{ background: "#fff" }}
              >
                <InputLabel id="salutation-label">Title</InputLabel>
                <Select
                  labelId="salutation-label"
                  id="salutation"
                  name="title"
                  value={formData?.title}
                  onChange={handleChange}
                  label="title"
                >
                  <MenuItem value="Dr.">Dr.</MenuItem>
                  <MenuItem value="Mr.">Mr.</MenuItem>
                  <MenuItem value="Mrs.">Mrs.</MenuItem>
                  <MenuItem value="Ms.">Ms.</MenuItem>
                  <MenuItem value="NA">NA</MenuItem>
                </Select>
              </FormControl>
              <TextField
                id="firstName"
                name="first_name"
                label="First Name"
                variant="outlined"
                value={formData?.first_name}
                onChange={handleChange}
                required
                autoFocus
                className={classes.nameField}
                style={{ background: "#fff" }}
              />
              <TextField
                id="lastName"
                name="last_name"
                label="Last Name"
                variant="outlined"
                value={formData?.last_name}
                onChange={handleChange}
                required
                className={classes.nameField}
                style={{ background: "#fff" }}
              />
            </div>
            <FormControl
              variant="outlined"
              className={classes.gender}
              fullWidth
              style={{ width: "100%", background: "#fff" }}
              required
            >
              <InputLabel id="gender">Gender</InputLabel>
              <Select
                labelId="gender"
                id="gender"
                name="gender"
                label="Gender"
                value={formData?.gender}
                onChange={handleChange}
              >
   <MenuItem value="MALE">Male</MenuItem>
<MenuItem value="FEMALE">Female</MenuItem>
<MenuItem value="OTHER">Other</MenuItem>
              </Select>
            </FormControl>
            <TextField
              id="profile_title"
              name="profile_title"
              label="Your profile title"
              variant="outlined"
              value={formData?.profile_title}
              onChange={handleChange}
              required
              autoFocus
              fullWidth
              style={{ background: "#fff" }}
            />

            <TextField
              id="profile_details"
              name="profile_details"
              label="Add your expertise, achievements, and more"
              variant="outlined"
              value={formData?.profile_details}
              onChange={handleChange}
              required
              fullWidth
              multiline
              rows={6}
              style={{ borderRadius: "15px", background: "#fff" }}
            />
            <p
              style={{
                textAlign: "center",
                fontSize: "11px",
                marginTop: "-3px",
              }}
            >
              We have generated content for you using AI. Please feel free to
              edit.
            </p>

            <FormControl
              variant="outlined"
              fullWidth
              style={{ background: "#fff" }}
            >
              <InputLabel id="years-of-experience-label">
                Years of Experience*
              </InputLabel>
              <Select
                labelId="years-of-experience-label"
                id="years-of-experience"
                name="years_of_experience"
                value={formData?.years_of_experience}
                onChange={handleChange}
                label="Years of Experience"
              >
                {numbers.map((number) => (
                  <MenuItem
                    key={number}
                    value={number}
                    style={{ minWidth: "333px", left: "" }}
                  >
                    {number} Year{number !== 1 ? "s" : ""}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl
              variant="outlined"
              fullWidth
              style={{ background: "#fff" }}
            >
              <InputLabel id="registration-council-label">
                Select one or many languages
              </InputLabel>
              <Select
                labelId="registration-council-label"
                id="languages_list"
                name="languages_list"
                value={formData?.languages_list}
                onChange={handleChange}
                label="Registration Council"
                required
                multiple // Enable multiple selections
              >
                {Object.keys(secondaryLanguages)?.map((item) => (
                  <MenuItem
                    selected={
                      formData?.languages_list === secondaryLanguages[item]
                    }
                    value={item}
                  >
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
              variant="outlined"
              fullWidth
              style={{ background: "#fff" }}
            >
              <InputLabel id="speciality-label">
                {specializations_list.length > 0
                  ? "Specialities Selected"
                  : "Select your specializations*"}
              </InputLabel>
              <Select
                labelId="speciality-label"
                id="speciality"
                name="speciality"
                multiple
                value={specializations_list}
                onChange={handleChang}
                label="Speciality"
                open={menuOpen}
                onOpen={() => setMenuOpen(true)}
                MenuProps={{
                  onClose: handleMenuClose,
                }}
                renderValue={(selected) => (
                  <div>
                    {selected.map((value) => (
                      <span
                        key={value}
                        style={{
                          backgroundColor: "#d9d9d9",
                          padding: "2px",
                          marginRight: "5px",
                          borderRadius: "7px",
                        }}
                      >
                        {value}
                      </span>
                    ))}
                  </div>
                )}
              >
                <CloseIcon
                  onClick={handleMenuClose}
                  style={{
                    float: "right",
                    marginRight: "1px",
                    cursor: "pointer",
                  }}
                />
                {specialData.map((specialization, index) => (
                  <MenuItem key={index} value={specialization.specialization}>
                    {specialization.specialization}
                  </MenuItem>
                ))}
              </Select>
              <Typography
                variant="body2"
                color="textSecondary"
                align="center"
                style={{ background: "#e8f3f1" }}
              >
                Or
              </Typography>

              {specialData.findIndex(
                (specialization) =>
                  specialization.value === specializations_list
              ) === -1 && (
                <TextField
                  id="specializations_list"
                  name="specializations_list"
                  label="Enter your specialization"
                  variant="outlined"
                  fullWidth
                  value={textFieldSpecializations}
                  onChange={handleTextFieldSpecializationsChange}
                  style={{ marginLeft: "1px" }}
                />
              )}
            </FormControl>
            <InputLabel
              id="registrations_list"
              style={{
                marginLeft: "-240px",
                marginBottom: "3px",
                marginTop: "30px",
              }}
            >
              Add Registration
            </InputLabel>
            <TextField
              id="registrations_list"
              name="registrations_list"
              variant="outlined"
              value={formData.registrations_list
                .map((registrations) => registrations.registration_id)
                .join(", ")}
              onChange={handleRegistrationChang}
              onClick={handleOpenRegistrationModal}
              required
              fullWidth
              InputProps={{
                endAdornment: (
                  <ArrowRightIcon
                    style={{ cursor: "pointer" }}
                    onClick={handleOpenRegistrationModal}
                  />
                ),
              }}
              style={{ background: "#fff" }}
            />
            <InputLabel
              id="memberships_list"
              style={{
                marginLeft: "-240px",
                marginBottom: "3px",
                marginTop: "30px",
              }}
            >
              Add Memberships
            </InputLabel>
            <TextField
              id="memberships_list"
              name="memberships_list"
              // label="Add Memberships"
              variant="outlined"
              // value={formData?.memberships_list[0]?.member_of || ""}
              value={formData.memberships_list
                .map((memberships) => memberships.member_of)
                .join(", ")}
              onChange={handleMembershipChang}
              onClick={handlemem}
              // required
              fullWidth
              InputProps={{
                endAdornment: (
                  <ArrowRightIcon
                    style={{ cursor: "pointer" }}
                    onClick={handlemem}
                  />
                ),
              }}
              style={{ background: "#fff" }}
            />

            <Box sx={{ marginBottom: "3px", width: "396px" }}>
            <PrescriptionEditor
  fetchType="symptoms_list"
  editable={true}
  placeholder={"Select one or many practice areas"}
  changeHandler={updatePData}
  asyncData={true}
  pdataObj={formData?.practice_areas_list || []}
  receiveNotes={receiveNotes["symptoms_list"] || []}
/>
            </Box>
            <Button
              className={classes.button}
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              style={{ height: "40px" }}
            >
              Save
            </Button>
          </form>
        </Container>
      </div>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 430,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: "20px",
          }}
        >
          <CloseIcon
            onClick={handleCloseModal}
            style={{ float: "right", cursor: "pointer" }}
          />
          <p
            style={{ textAlign: "center", fontSize: "20px", fontWeight: "500" }}
          >
            Add New Registration
          </p>

          <form>
            {formData.registrations_list.map((registrations, index) => (
              <div
                key={index}
                style={{
                  border: filledFields[index] ? "2px solid lightgray" : "none",
                  width: "385px",
                  margin: "10px",
                  marginLeft: "-20px",
                  marginBottom: "10px",
                  borderRadius: filledFields[index] ? "10px" : "none",
                  // backgroundColor: filledFields[index] ? "lightgray" : "",
                }}
              >
                <Grid
                  container
                  spacing={2}
                  style={{ marginLeft: "26px", marginBottom: "-20px" }}
                >
                  <Grid item xs={12} sm={5}>
                    <TextField
                      fullWidth
                      label="Registration ID"
                      variant="outlined"
                      name="registration_id"
                      value={registrations.registration_id || ""}
                      onChange={(event) => {
                        handleRegistrationChange(index, event);
                      }}
                      margin="normal"
                      required
                      style={{ marginBottom: "21px", backgroundColor: "#fff" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <TextField
                      fullWidth
                      label="Valid From"
                      variant="outlined"
                      name="valid_from"
                      value={registrations.valid_from || ""}
                      onChange={(event) => {
                        handleRegistrationChange(index, event);
                      }}
                      margin="normal"
                      required
                      style={{ marginBottom: "30px", backgroundColor: "#fff" }}
                    />
                  </Grid>
                </Grid>
                <FormControl
                  variant="outlined"
                  fullWidth
                  style={{
                    marginLeft: "35px",
                    maxWidth: "336px",
                    marginBottom: "10px",
                    backgroundColor: "#fff",
                  }}
                >
                  <InputLabel id="registration-council-label">
                    Select registration council*
                  </InputLabel>
                  <Select
                    labelId="registration-council-label"
                    id="registration-council"
                    name="registration_council"
                    value={registrations.registration_council || ""}
                    onChange={(event) => {
                      handleRegistrationChange(index, event);
                    }}
                    label="Registration Council"
                    required
                  >
                    {RegistrationCouncil.map((council) => (
                      <MenuItem key={council.value} value={council.value}>
                        {council.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <RemoveCircleOutlineIcon
                  style={{
                    marginTop: "-21px",
                    cursor: "pointer",
                    marginBottom: "44px",
                    float: "right",
                    marginRight: "-29px",
                  }}
                  onClick={() => handleRemoveExperince(index)}
                />
              </div>
            ))}
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleAddServic}
              style={{ height: "40px", width: "50px", marginLeft: "336px" }}
            >
              Add+
            </Button>

            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              fullWidth
              style={{ height: "40px" }}
              onClick={handleEducationSubmit} // Use the education submit handler
            >
              Add Registration
            </Button>
          </form>
        </Box>
      </Modal>

      <Modal
        open={openMemModal}
        onClose={handleCloseMo}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 430,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: "20px",
          }}
        >
          <CloseIcon
            onClick={handleCloseMo}
            style={{ float: "right", cursor: "pointer" }}
          />
          <p
            style={{ textAlign: "center", fontSize: "20px", fontWeight: "500" }}
          >
            Add New Membership
          </p>

          <form>
            {formData.memberships_list.map((membership, index) => (
              <div
                key={index}
                style={{
                  border: filledFields[index] ? "2px solid lightgray" : "none",
                  width: "397px",
                  // margin: "10px",
                  marginLeft: "-18px",
                  marginBottom: "10px",
                  borderRadius: filledFields[index] ? "10px" : "none",
                }}
              >
                <Grid
                  container
                  spacing={2}
                  style={{ marginLeft: "26px", marginBottom: "-20px" }}
                >
                  <Grid item xs={12} sm={5}>
                    <TextField
                      fullWidth
                      label="Member From"
                      variant="outlined"
                      name="member_from"
                      value={membership.member_from || ""}
                      onChange={(event) => {
                        handleMembershipChange(index, event);
                      }}
                      margin="normal"
                      required
                      style={{ marginBottom: "21px", backgroundColor: "#fff" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <TextField
                      fullWidth
                      label="Valid From"
                      variant="outlined"
                      name="valid_from"
                      value={membership.valid_from || ""}
                      onChange={(event) => {
                        handleMembershipChange(index, event);
                      }}
                      margin="normal"
                      required
                      style={{ marginBottom: "30px", backgroundColor: "#fff" }}
                    />
                  </Grid>
                </Grid>
                <TextField
                  fullWidth
                  label="Enter name of Organization"
                  variant="outlined"
                  name="member_of"
                  value={membership.member_of || ""}
                  onChange={(event) => {
                    handleMembershipChange(index, event);
                  }}
                  margin="normal"
                  required
                  style={{
                    marginLeft: "35px",
                    maxWidth: "336px",
                    marginBottom: "10px",
                    backgroundColor: "#fff",
                  }}
                />
                <RemoveCircleOutlineIcon
                  style={{
                    marginTop: "-21px",
                    cursor: "pointer",
                    marginBottom: "44px",
                    float: "right",
                    marginRight: "-15px",
                  }}
                  onClick={() => handleRemoveMember(index)}
                />
              </div>
            ))}
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleAddService}
              style={{ height: "40px", width: "50px", marginLeft: "291px" }}
            >
              Add+
            </Button>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              fullWidth
              style={{ height: "40px" }}
              onClick={handleExperienceSubmit} // Use the experience submit handler
            >
              Add Membership
            </Button>
          </form>
        </Box>
      </Modal>
    </>
  );
};
export default BasicDetails;
