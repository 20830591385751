import React, { useState, useEffect, useContext } from "react";
import ButtonTool from "../Tools/Button";
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  TextField,
  Typography,
  Grid,
  Button,
  CircularProgress,
} from "@mui/material";
import GlobalContext, { PatientpageContext } from "../context/GlobalContext";

export default function ProfileForm() {
  const doctorContext = useContext(PatientpageContext);
  const [profileVal, setProfileVal] = useState({
    qualification: "",
    firstname: "",
    speciality: "",
    phone: "",
    lastname: "",
    gender: "male",
    designations:''
  });

  useEffect(() => {
    if (doctorContext.profileData) {
      const {
        qualification,
        firstname,
        specialization,
        phone,
        lastname,
        gender,
        designations,
      } = doctorContext.profileData;

      setProfileVal({
        ...profileVal,
        qualification: qualification?.toString(),
        firstname,
        lastname,
        speciality: specialization?.toString(),
        phone,
        lastname,
        gender,
        designations,
      });
    }
  }, []);
  const changeProfile = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setProfileVal({ ...profileVal, [name]: value });
  };

  const submitProfile = (e) => {
    e.preventDefault();
    delete profileVal?.phone;
    doctorContext.submitProfile(profileVal);
  };
  return (
    <form onSubmit={submitProfile} className="px-1">
      <Typography
        className="text-center d-none"
        variant="h6"
        gutterBottom
        component="div"
      >
        Profile Form
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="firstname"
            name="firstname"
            fullWidth
            label="First Name"
            value={profileVal.firstname}
            variant="standard"
            // inputProps={{ autoComplete: 'off' }}
            onChange={changeProfile}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="lastname"
            name="lastname"
            fullWidth
            label="Last Name"
            value={profileVal.lastname}
            variant="standard"
            // inputProps={{ autoComplete: 'off' }}
            onChange={changeProfile}
          />
        </Grid>

        {/* <Grid item xs={12} sm={6}>
          <TextField
            id="speciality"
            fullWidth
            name="speciality"
            label="Speciality"
            variant="standard"
            value={profileVal.speciality}
            onChange={changeProfile}
          />
        </Grid> */}
        {/* <Grid item xs={12} sm={6}>
          <TextField
            id="qualification"
            fullWidth
            name="qualification"
            label="Qualification"
            variant="standard"
            value={profileVal.qualification}
            onChange={changeProfile}
          />
        </Grid> */}
        
        <Grid xs={12} sm={6} item>
          <FormControl variant="standard" style={{ width: "100%" }}>
            <InputLabel id="demo-simple-select-helper-label">Gender</InputLabel>
            <Select
              labelId="genderLabel"
              label="Gender"
              id="gender"
              name="gender"
              value={profileVal.gender}
              fullWidth
              onChange={changeProfile}
            >
              <MenuItem value="">Select Gender</MenuItem>
              <MenuItem value="male">Male</MenuItem>
              <MenuItem value="female">Female</MenuItem>
              <MenuItem value="other">Other</MenuItem>
              <MenuItem value="dontdisclose">Don't want to disclose</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid xs={12} sm={6} item>
          <TextField
            variant="standard"
            required
            label="phone"
            id="phone"
            name="phone"
            onChange={changeProfile}
            value={profileVal.phone}
            fullWidth
            disabled
          />
        </Grid>
        {/* <Grid item xs={12} sm={12}>
          <TextField
            id="designations"
            fullWidth
            name="designations"
            label="Designations"
            variant="standard"
            value={profileVal.designations}
            onChange={changeProfile}
          />
        </Grid> */}
      </Grid>
      <Grid container className="text-center pt-5 justify-content-center">
        <ButtonTool
          text={
            doctorContext.submitProfileLoader ? (
              <CircularProgress color="success" size={15} />
            ) : (
              "Save"
            )
          }
          disabled={doctorContext.submitProfileLoader}
        >
          {doctorContext.submitProfileLoader && (
            <CircularProgress color="success" size={15} />
          )}
        </ButtonTool>
        {/* <Button
          variant="contained"
          color="error"
          className="mx-1"
          disabled={doctorContext.submitProfileLoader}
          onClick={doctorContext.handleDialogClose}
        >
          Cancel
        </Button> */}
      </Grid>
    </form>
  );
}
