import { useState, useEffect, useContext } from "react";
import {
  TextField,
  Button,
  Container,
  makeStyles,
  FormControl,
  InputLabel,
  InputAdornment,
  Input,
  MenuItem,
  Select,
  Modal,
  Typography,
  Grid,
  IconButton,
  Box,
  FormHelperText,
} from "@material-ui/core";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useHistory, useLocation } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import { ServiceCall } from "../utility/service-call";
import { Ls } from "../utility/utility";
import { constants } from "../utility/constants";
import CircularProgress from "@material-ui/core/CircularProgress";
import { PatientpageContext } from "../context/GlobalContext";
import PageContentLayout from "./PageContentLayout";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(4),
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
    "& .MuiFormControl-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(2, 0),
    backgroundColor: "#199a8e",
    "&:hover": {
      // Remove hover effect
      backgroundColor: "#199a8e",
    },
  },
}));
const Sociallink = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [saved, setSaved] = useState(false);
  const doctorContext = useContext(PatientpageContext);

  const [apiError, setApiError] = useState("");

  const setState = doctorContext.setState;

  const [formData, setFormData] = useState({
    facebook: "",
    linkedin: "",
    instagram: "",
    twitter: "",
  });

  const handlle = () => {
    history.push({
      pathname: "/my-profile",
      search: history.location.search,
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await ServiceCall.getv2(
          "/v2/doctor/myprofile/social/links/",
          `${constants.hospitalId("hosp_id")}`,
          {
            doc_secret: Ls.get("docToken"),
            doc_key: Ls.get("docKey"),
          }
        );

        const { social_links } = response.data; // Extract social links from response data
        // Set form data with fetched social links
        setFormData({
          facebook: social_links.facebook || "", // Set Facebook link or empty string if not provided
          linkedin: social_links.linkedin || "", // Set LinkedIn link or empty string if not provided
          instagram: social_links.instagram || "", // Set Instagram link or empty string if not provided
          twitter: social_links.twitter || "", // Set Twitter link or empty string if not provided
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    try {
      const Handlesub = await ServiceCall.putv2(
        "/v2/doctor/myprofile/social/links/",
        `${constants.hospitalId("hosp_id")}`,
        formData,
        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      );
      if (Handlesub) {
        setLoading(false);
        setState({
          msg: Handlesub?.data.message || "",
          type: "success",
          timer: null,
          vertical: "top",
          horizontal: "center",
        });

      }
      await new Promise((resolve) => setTimeout(resolve, 1000)); // Simulate API call
      setSaved(true);
    } catch (err) {
      if (err.response) {
        setApiError(err.response.data.message);
        setState({
          msg: err?.response?.data?.message || "",
          type: "error",
          timer: null,
          vertical: "top",
          horizontal: "center",
        });
      } else if (err.request) {
        setApiError("Failed request " + err);
      } else {
        setApiError("Failed in general " + err);
      }
    }
  };

  

  return (

    <div style={{background: "rgb(232 243 241)"}}>
      <Container component="main" maxWidth="xs">
   
        <div className="prescriptionContainer__heading" style={{marginLeft: "0.5rem"}}>

          Add social links to display on your profile, Once done, please click
          SAVE.
        </div>
        <form className={classes.root} onSubmit={handleSubmit} style={{marginTop: "2rem"}}>
          <TextField
            id="facebook"
            name="facebook"
            label="Paste your Facebook profile link here "
            variant="outlined"
            value={formData?.facebook}
            onChange={handleChange}
            autoFocus
            fullWidth
            style={{background: "#fff"}}
          />

          <TextField
            id="instagram"
            name="instagram"
            label="Paste your LinkedIn profile link here"
            variant="outlined"
            value={formData?.instagram}
            onChange={handleChange}
            fullWidth
            style={{background: "#fff"}}

          />

          <TextField
            id="linkedin"
            name="linkedin"
            label="Paste your Instagram profile link here"
            variant="outlined"
            value={formData?.linkedin}
            onChange={handleChange}
            fullWidth
            style={{background: "#fff"}}

          />

          <TextField
            id="twitter"
            name="twitter"
            label="Paste your Twitter profile link here"
            variant="outlined"
            value={formData?.twitter}
            onChange={handleChange}
            required
            fullWidth
            style={{background: "#fff"}}

          />

          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
            style={{ height: "40px", marginTop: "50px" }}
            // disabled={saved}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : saved ? (
              "Saved"
            ) : (
              "Save"
            )}
          </Button>
        </form>
      </Container>
    </div>

  );
};
export default Sociallink;
