import { CircularProgress, Grid } from "@mui/material";
import { useEffect, useRef } from "react";
import { Loader } from "@googlemaps/js-api-loader";

import styles from "./Map.module.css";

const loader = new Loader({
  apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  version: "weekly",
  libraries: ["places"],
});

const initialValues = {
  google_place_id: "",
  address: "",
  google_geo_lat: "",
  google_geo_lng: "",
};


const Map = ({ currentValues, updateGoogleMapValues }) => {
  const mapRef = useRef(null);
  const pacInput = useRef(null);
  const infowindowContentRef = useRef(null);

  useEffect(() => {
    const showMap = async () => {
      const placeId = currentValues.google_place_id;
      let defaultMapOptions = {};
      if (currentValues.google_geo_lat && currentValues.google_geo_lng) {
        defaultMapOptions = {
          center: {
            lat: parseFloat(currentValues.google_geo_lat),
            lng: parseFloat(currentValues.google_geo_lng),
          },
          zoom: 7,
        };
      } else {
        defaultMapOptions = {
          center: {
            lat: 20.5937,
            lng: 78.9629,
          },
          zoom: 4,
        };
      }

      loader.load().then((google) => {
        const map = new google.maps.Map(mapRef.current, defaultMapOptions);
        const geocoder = new google.maps.Geocoder();
        const input = pacInput.current;
        const autocomplete = new google.maps.places.Autocomplete(input, {
          fields: ["place_id", "geometry", "formatted_address", "name"],
        });
        autocomplete.bindTo("bounds", map);
        map.controls[google.maps.ControlPosition.TOP_CENTER].push(input);

        const infowindow = new google.maps.InfoWindow();
        const infowindowContent = infowindowContentRef.current;
        infowindow.setContent(infowindowContent);

        let marker = new google.maps.Marker({ map: map });
        marker.addListener("click", () => {
          infowindow.open(map, marker);
        });

        if (
          "google_place_id" in currentValues &&
          currentValues.google_place_id.length
        ) {
          // console.log("first first first");
          geocodePlaceId(
            geocoder,
            map,
            placeId,
            marker,
            infowindow,
            infowindowContent
          );
        }

        google.maps.event.addListener(map, "click", function (event) {
          customMarker(
            event,
            geocoder,
            map,
            placeId,
            marker,
            infowindow,
            infowindowContent
          );
        });

        autocomplete.addListener("place_changed", () => {
          infowindow.close();
          placeChanged(
            geocoder,
            map,
            placeId,
            marker,
            infowindow,
            infowindowContent
          );
        });

        function placeChanged(
          geocoder,
          map,
          placeId,
          marker,
          infowindow,
          infowindowContent
        ) {
          const place = autocomplete.getPlace();

          if (!place.geometry || !place.geometry.location) {
            return;
          }

          if (place.geometry.viewport) {
            map.fitBounds(place.geometry.viewport);
          } else {
            map.setCenter(place.geometry.location);
            map.setZoom(20);
          }

          marker.setPlace({
            placeId: place.place_id,
            location: place.geometry.location,
          });
          updateGoogleMapValues({
            google_place_id: place.place_id,
            address: place.formatted_address,
            google_geo_lat: place.geometry.location.lat().toString(),
            google_geo_lng: place.geometry.location.lng().toString(),
          });
          marker.setVisible(true);
          infowindowContent.children.namedItem("place-address").textContent =
            place.formatted_address;
          infowindow.open(map, marker);
        }

        function customMarker(
          event,
          geocoder,
          map,
          placeId,
          marker,
          infowindow,
          infowindowContent
        ) {
          const latitude = event.latLng.lat();
          const longitude = event.latLng.lng();
          var latlng = {
            lat: parseFloat(latitude),
            lng: parseFloat(longitude),
          };

          geocoder.geocode({ location: latlng }, function (results, status) {
            if (status === google.maps.GeocoderStatus.OK) {
              if (results[1]) {
                map.setZoom(20);
                marker.setPlace({
                  placeId: results[1].place_id,
                  location: latlng,
                });
                updateGoogleMapValues({
                  google_place_id: results[1].place_id,
                  address: results[1].formatted_address,
                  google_geo_lat: results[1].geometry.location.lat().toString(),
                  google_geo_lng: results[1].geometry.location.lng().toString(),
                });
                infowindowContent.children.namedItem(
                  "place-address"
                ).textContent = results[1].formatted_address;
                infowindow.open(map, marker);
              }
            }
          });
        }

        function geocodePlaceId(
          geocoder,
          map,
          placeId,
          marker,
          infowindow,
          infowindowContent
        ) {
          geocoder
            .geocode({ placeId: placeId })
            .then(({ results }) => {
              // console.log("results", results);
              if (results[0]) {
                map.setZoom(20);
                map.setCenter(results[0].geometry.location);

                marker.setPlace({
                  placeId: placeId,
                  location: results[0].geometry.location,
                });
                updateGoogleMapValues({
                  google_place_id: placeId,
                  address: results[0].formatted_address,
                  google_geo_lat: results[0].geometry.location.lat().toString(),
                  google_geo_lng: results[0].geometry.location.lng().toString(),
                });
                infowindowContent.children.namedItem(
                  "place-address"
                ).textContent = results[0].formatted_address;
                infowindow.open(map, marker);
              }
            })
            .catch(() => {});
        }
      });
    };

    showMap();
    return () => {
      updateGoogleMapValues(initialValues);
    };
  }, [currentValues]);

  return (
    <Grid container>
      <div style={{ display: "block" }}>
        <input
          ref={pacInput}
          className={`controls ${styles.input}`}
          type="text"
          placeholder="Enter a location"
        />
      </div>

      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        style={{
          height: "60vh",
          width: "100%",
        }}
        alignItems="center"
        justifyContent="center"
        display="flex"
        ref={mapRef}
      >
        <CircularProgress sx={{ color: "#00b0f0" }} />
      </Grid>
      <div ref={infowindowContentRef}>
        <span id="place-address"></span>
      </div>
    </Grid>
  );
};

export default Map;
