import { useEffect, useState, useContext } from "react";
import {
  TextField,
  Button,
  Container,
  makeStyles,
  FormControl,
  InputLabel,
  InputAdornment,
  Input,
  MenuItem,
  Select,
  Modal,
  Typography,
  Grid,
  IconButton,
  FormHelperText,
} from "@material-ui/core";
import Doctorregistration from "../../static/images/Doctorregister.png";
import { ServiceCall } from "../../utility/service-call";
import { Ls } from "../../utility/utility";
import RegitrationCouncil from "./Regitration-Council";
import Map from "../../components/Map/index";
import AddLocationIcon from "@mui/icons-material/AddLocation";
import Footer from "../../components/Footer";
import { useHistory, useLocation } from "react-router-dom";
import { constants } from "../../utility/constants";
import { PatientpageContext } from "../../context/GlobalContext";
// import Homepage from "./Homepage";
import Header from "../../components/Header";
import { HistoryOutlined } from "@material-ui/icons";
import { countriesList } from "../../utility/utility";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(4),
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
    "& .MuiFormControl-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(2, 0),
    backgroundColor: "#199a8e",
    "&:hover": {
      // Remove hover effect
      backgroundColor: "#199a8e",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "999 !important",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 10),
  },
}));

const Doctordetails = ({ Docdata }, props) => {
  const detailss = Docdata;
  const doctorContext = useContext(PatientpageContext);

  const numbers = Array.from({ length: 100 }, (_, i) => i + 1);

  const classes = useStyles();
  const [phoneCode, setPhoneCode] = useState("+91");

  const [apiSuccess, setApiSuccess] = useState(false);

  const [specialData, setSpecialData] = useState([]);
  const [isSpecialitySelected, setIsSpecialitySelected] = useState(false);
  const [specializations_list, setSelectedSpecializations] = useState([]);
  const [getMapData, setMapData] = useState({});
  const [showMapModal, setShowMapModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [phoneError, setPhoneError] = useState(false);

  const [resDta, setResDta] = useState("");
  const history = useHistory();
  const [moddata, setModdata] = useState("");
  const shouldShowModal = moddata && moddata.show_prompt;
  const [refdata, setRefdata] = useState("");
  const [addressFieldFocused, setAddressFieldFocused] = useState(false);
  const [addressFieldBlurred, setAddressFieldBlurred] = useState(false);
  const [loading, setLoading] = useState(false); // State for managing loading state
  const [hdata, setHdata] = useState("");
  const [menuOpen, setMenuOpen] = useState(false);
  const [doctorsom, setDoctorsom] = useState([]);

  const [formData, setFormData] = useState({
    som: "Modern Medicine",
    registration_id: "",
    qualification: "",
    registration_council: "",
    facility_name: "",
    facility_phone: "",
    years_of_experience: "",
    google_place_id: "",
    google_geo_lat: "",
    google_geo_lng: "",
  });
  const [add, setAdd] = useState({
    facility_address: "",
  });
  const [textFieldSpecializations, setTextFieldSpecializations] = useState("");

  // Update handleChang function to handle TextField specializations input
  const handleTextFieldSpecializationsChange = (event) => {
    setTextFieldSpecializations(event.target.value);
  };

  const isValidPhoneNumber = (phoneNumber) => {
    // Remove any non-numeric characters from the phone number
    const numericPhoneNumber = phoneNumber.replace(/\D/g, "");

    // Check if the phone number has exactly 10 digits and contains only numeric characters
    return numericPhoneNumber.length === 10 && /^\d+$/.test(numericPhoneNumber);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormData({ ...formData, [name]: value });
    if (name === "facility_phone") {
      if (!isValidPhoneNumber(value)) {
        // Define isValidPhoneNumber according to your validation logic
        setPhoneError(true); // Set phone number error state
      } else {
        setPhoneError(false); // Reset phone number error state
      }
    }
    if (name === "facility_address") {
      // Update the locaData state with the new address data
      setAdd({ ...add, [name]: value });
    }
  };

  useEffect(() => {
    const Doctorspe = async () => {
      try {
        const doctorsm = await ServiceCall.newget("v2/facility/som/lookup");
        if (doctorsm) {
          setDoctorsom(doctorsm.data.records);
        }
      } catch (err) {}
    };
    Doctorspe();
  }, []);

  // useEffect(() => {
  //   if (typeof window !== "undefined") {
  //     window.history.pushState(null, null, window.location.herf);
  //     window.onpopstate = () => {
  //       window.history.go(1);
  // doctorContext?.dialogController({
  //   open: true,
  //   content: (() => (
  //     <div className="appointment__screen__exit">
  //       Do you really wish to exit?
  //     </div>
  //   ))(),
  //   checkLoginPage: false,
  //   promptWindow: true,
  //   dialogAction: (() => (
  //     <div className="appointment__screen__exit__buttons">
  //       <Button
  //         handleClick={() => {
  //           doctorContext.handleDialogClose();
  //           Ls.remove("pt_key");
  //           Ls.remove("pt_token");
  //           history.push({
  //             pathname: "/",
  //           });
  //         }}
  //         className="mr-2"
  //         text="Exit"
  //         containerClass="m-auto appointment__screen__verify"
  //       ></Button>
  //       <Button
  //         handleClick={() => {
  //           doctorContext.handleDialogClose();
  //         }}
  //         className="mr-2"
  //         text="CANCEL"
  //         containerClass="m-auto appointment__screen__verify"
  //       ></Button>
  //     </div>
  //   ))(),
  //   maxWidth: "sm",
  //   disableBackdropClick: true,
  // });
  //     };
  //   }
  //   return () => {
  //     if (typeof window !== "window") {
  //       window.onpopstate = null;
  //     }
  //   };
  // }, []);

  const handleChan = (event) => {
    setPhoneCode(event.target.value);
  };

  const handleChang = (event) => {
    const { value } = event.target;
    setSelectedSpecializations(value); // Update selected specializations
  };
  const handleCloseMapModal = () => {
    setShowMapModal(false);
  };

  const handleShowMapModal = () => {
    setShowMapModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleCloseModaldtaModal = () => {
    setShowModal(false);
  };

  // const [locaData, setLocaData] = useState({
  //   google_place_id: "",
  //   facility_address: "",
  //   google_geo_lat: "",
  //   google_geo_lng: "",
  // });

  const handleUpdateGoogleMapPlace = (values) => {
    setFormData({
      ...formData,
      // facility_address: values.address,
      google_place_id: values.google_place_id,
      google_geo_lat: values.google_geo_lat,
      google_geo_lng: values.google_geo_lng,
    });
  };

  const saveGoogleMapValues = () => {
    setMapData(formData);
    handleCloseMapModal();
  };

  const modaldta = async () => {
    try {
      const headers = {
        doc_secret: Docdata.doc_secret,
        doc_key: Docdata.doc_key,
      };
      const mdata = await ServiceCall.gett(
        "/v2/fetch/doctor/login/details",
        headers
      );
      if (mdata) {
        setModdata(mdata.data);
        setRefdata(mdata.data);
      }
    } catch (err) {}
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const body = {
        specializations_list: [
          ...specializations_list,
          textFieldSpecializations,
        ],
        som: formData?.som,
        registration_id: formData?.registration_id,
        qualification: formData?.qualification,
        registration_council: formData?.registration_council,
        facility_name: formData?.facility_name,
        facility_phone: "+91" + formData?.facility_phone,
        facility_address: add?.facility_address,
        ...getMapData,
      };
      const headers = {
        doc_secret: Docdata.doc_secret,
        doc_key: Docdata.doc_key,
      };
      const hos_id = Docdata.hos_id;

      const response = await ServiceCall.putv2(
        "/v2/doctor/medical/registration/details/",
        `${hos_id}`,
        body,
        headers
      );

      // Check if the response is successful
      if (response) {
        setLoading(false);
        setHdata(response.data);

        console.log("API call successful:", response);
        // modaldta();
        setShowModal(true);
      } else {
        console.error("API call failed:", response);
      }
    } catch (error) {
      console.error("Error occurred during API call:", error);
    }
  };

  useEffect(() => {
    const Getspecialization = async () => {
      try {
        const specialization = await ServiceCall.getnewv3(
          "/hospital/list/medical/specializations"
        );
        if (specialization) {
          setSpecialData(specialization.data.records);
        }
      } catch (err) {
        console.error("Error fetching specializations:", err);
      }
    };
    Getspecialization();
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // Cancel the event
      event.preventDefault();
      // Chrome requires returnValue to be set
      event.returnValue = "";
    };

    // Add the event listener when the component mounts
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const handleProceedModaldta = () => {
    Ls.set("docToken", Docdata.doc_secret);
    Ls.set("docKey", Docdata.doc_key);
    Ls.set("hosp_id", Docdata.hos_id);

    history.push({
      pathname: "/home",
      search: `?mode=${constants.testPath("mode")}&hosp_id=${Docdata?.hos_id}`,
    });
  };

  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  return (
    <>
      <Header page="login" refdata={refdata} />

      <div className="loginCo bodyBg" style={{ height: "200vh" }}>
        <div className="homepage">
          <Container component="main" maxWidth="xs">
            <form className={classes.root} onSubmit={handleSubmit}>
              <img
                src={Doctorregistration}
                alt="Registration"
                style={{
                  marginBottom: "-7px",
                  borderRadius: "5px",
                  height: "281px",
                }}
              />
              <h3
                style={{
                  fontWeight: "600",
                  color: "#8b9fb3",
                  marginTop: "-14px",
                }}
              >
                Your medical registration details
              </h3>
              <p
                style={{
                  color: "#8b9fb3",
                  textAlign: "center",
                  marginTop: "-11px",
                }}
              >
                To ensure RxOne remains a safe and trustworthy platform we
                verify every provider’s identity
              </p>

              <FormControl variant="outlined" fullWidth>
                <InputLabel id="som">Select Speciality Of Medicine</InputLabel>
                <Select
                  labelId="registration-council-label"
                  id="registration-council"
                  name="som"
                  value={formData?.som}
                  onChange={handleChange}
                  label="som"
                  autoFocus
                >
                  {doctorsom.map((doctortitle, index) => (
                    <MenuItem key={index} value={doctortitle?.title}>
                      {doctortitle?.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl variant="outlined" fullWidth>
                <InputLabel id="speciality-label">
                  {specializations_list.length > 0
                    ? "Specialities Selected"
                    : "Select your specializations*"}
                </InputLabel>
                <Select
                  labelId="speciality-label"
                  id="speciality"
                  name="speciality"
                  multiple
                  value={specializations_list}
                  onChange={handleChang}
                  label="Speciality"
                  open={menuOpen}
                  onOpen={() => setMenuOpen(true)}
                  MenuProps={{
                    onClose: handleMenuClose,
                  }}
                  renderValue={(selected) => (
                    <div>
                      {selected.map((value) => (
                        <span
                          key={value}
                          style={{
                            backgroundColor: "#d9d9d9",
                            padding: "2px",
                            marginRight: "5px",
                            borderRadius: "7px",
                          }}
                        >
                          {value}
                        </span>
                      ))}
                    </div>
                  )}
                >
                  <CloseIcon
                    onClick={handleMenuClose}
                    style={{
                      float: "right",
                      marginRight: "25px",
                      cursor: "pointer",
                    }}
                  />
                  {specialData.map((specialization, index) => (
                    <MenuItem key={index} value={specialization.specialization}>
                      {specialization.specialization}
                    </MenuItem>
                  ))}
                </Select>
                {/* Or label */}
                <Typography
                  variant="body2"
                  color="textSecondary"
                  align="center"
                >
                  Or
                </Typography>
                {/* Alternate input field if the selected council is not in the list */}
                {specialData.findIndex(
                  (specialization) =>
                    specialization.value === specializations_list
                ) === -1 && (
                  <TextField
                    id="specializations_list"
                    name="specializations_list"
                    label="Enter your specialization"
                    variant="outlined"
                    fullWidth
                    autoFocus
                    value={textFieldSpecializations}
                    onChange={handleTextFieldSpecializationsChange}
                    style={{ marginLeft: "1px" }}
                  />
                )}
              </FormControl>
              <TextField
                id="firstName"
                name="registration_id"
                label="Enter your Registration ID (NMC)"
                variant="outlined"
                value={formData?.registration_id}
                onChange={handleChange}
                required
                fullWidth
              />
              <TextField
                id="firstName"
                name="qualification"
                label="Enter your qualifications {comma, separated}"
                variant="outlined"
                value={formData?.qualification}
                onChange={handleChange}
                required
                fullWidth
              />
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="registration-council-label">
                  Select registration council*
                </InputLabel>
                <Select
                  labelId="registration-council-label"
                  id="registration-council"
                  name="registration_council"
                  value={formData?.registration_council}
                  onChange={handleChange}
                  label="Registration Council"
                  // Set the required attribute for validation
                  required
                >
                  {RegitrationCouncil.map((council) => (
                    <MenuItem key={council.value} value={council.value}>
                      {council.label}
                    </MenuItem>
                  ))}
                </Select>
                {/* Or label */}
                {RegitrationCouncil.findIndex(
                  (council) => council.value === formData?.registration_council
                ) === -1 && (
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    align="center"
                  >
                    Or
                  </Typography>
                )}

                {RegitrationCouncil.findIndex(
                  (council) => council.value === formData?.registration_council
                ) === -1 && (
                  <TextField
                    id="registration-council-alt"
                    name="registration_council"
                    label="Enter registration council"
                    variant="outlined"
                    fullWidth
                    value={formData?.registration_council}
                    onChange={handleChange}
                    // Hide the field if the select field has a value
                    style={{
                      marginLeft: "1px",
                    }}
                  />
                )}
              </FormControl>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="years-of-experience-label">
                  Years of Experience*
                </InputLabel>
                <Select
                  labelId="years-of-experience-label"
                  id="years-of-experience"
                  name="years_of_experience"
                  value={formData?.years_of_experience}
                  onChange={handleChange}
                  label="Years of Experience"
                >
                  {numbers.map((number) => (
                    <MenuItem
                      key={number}
                      value={number}
                      style={{ minWidth: "333px", left: "" }}
                    >
                      {number} Year{number !== 1 ? "s" : ""}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                id="firstName"
                name="facility_name"
                label="Facility Name"
                variant="outlined"
                value={formData?.facility_name}
                onChange={handleChange}
                required
                fullWidth
              />
              <FormControl variant="outlined" style={{ width: "95%" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={phoneCode}
                    // onChange={handleChan}
                    className="w-23"
                    classes={{ select: classes.select }}
                  >
                    {countriesList &&
                      countriesList.map((item, index) => (
                        <MenuItem
                          value={item.code}
                          key={item.code + "-" + index}
                        >
                          {item.code}
                        </MenuItem>
                      ))}
                  </Select>
                  <InputLabel
                    htmlFor="facility_phone"
                    style={{ marginLeft: "101px" }}
                  >
                    Phone Number for bookings*
                  </InputLabel>
                  <Input
                    id="facility_phone"
                    name="facility_phone"
                    value={formData?.facility_phone}
                    onChange={handleChange}
                    label="Phone Number"
                    required
                    error={phoneError}
                    style={{ flex: 1, marginLeft: 6, width: "100px" }}
                  />
                </div>
                {phoneError && (
                  <FormHelperText error>
                    Please enter a valid phone number.
                  </FormHelperText>
                )}
              </FormControl>
              <TextField
                id="facilityAddress"
                name="facility_address"
                variant="outlined"
                value={add?.facility_address}
                onChange={handleChange}
                required
                fullWidth
                onFocus={() => setAddressFieldFocused(true)}
                onBlur={() => setAddressFieldBlurred(true)}
                error={addressFieldBlurred && !getMapData?.google_place_id}
                helperText={
                  addressFieldBlurred && !getMapData?.google_place_id
                    ? "Please select the location."
                    : ""
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleShowMapModal}
                        style={{ fontSize: "15px", fontWeight: "700" }}
                      >
                        Select Location
                        <AddLocationIcon style={{ color: "#cf3939" }} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <div
                className="footer-container"
                style={{
                  fontSize: "12px",
                  fontWeight: "700",
                  marginBottom: "10px",
                  marginTop: "10px",
                }}
              >
                By registering you agree to the{" "}
                <a
                  href="https://www.provider.rxone.app/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>{" "}
                and{" "}
                <a
                  href="https://www.provider.rxone.app/agreement"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Customer Agreement
                </a>
              </div>
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Register"
                )}
              </Button>
            </form>
          </Container>
        </div>
      </div>
      <Modal
        open={showMapModal}
        onClose={handleCloseMapModal}
        className={classes.modal}
      >
        <div className={classes.paper}>
          <Typography variant="h6" component="h2">
            Map
          </Typography>
          <div
            style={{
              width: "356px",
              height: "414px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Map
              currentValues={getMapData}
              updateGoogleMapValues={handleUpdateGoogleMapPlace}
            />
          </div>
          <Button
            onClick={saveGoogleMapValues}
            style={{
              backgroundColor: "rgb(77, 154, 166)",
              border: "none",
              borderRadius: "10px",
            }}
          >
            Save
          </Button>
        </div>
      </Modal>

      <Modal
        open={showModal}
        onClose={handleCloseModal}
        style={{
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginLeft: "-15px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            background: "white",
            maxWidth: "389px",
            borderRadius: "60px",
            height: "376px",
          }}
        >
          {hdata && (
            <>
              <Typography
                variant="h6"
                style={{ color: "grey", marginTop: "40px" }}
              >
                Welcome On Board!
              </Typography>
              <img
                src={hdata.image_url}
                alt="Modal Image"
                style={{ height: "100px", width: "100px", marginTop: "10px" }}
              />
              <Typography
                style={{
                  marginTop: "10px",
                  paddingLeft: "44px",
                  paddingRight: "40px",
                }}
              >
                {hdata.message}
              </Typography>
              <Typography
                style={{
                  marginTop: "10px",
                  marginLeft: "90px",
                  marginRight: "100px",
                }}
              >
                {/* {moddata.text} */}
              </Typography>
              <div className="btns">
                {/* <Button
                variant="contained"
                color="#199a8e"
                style={{ color: "#fff", backgroundColor: "grey" }}
                onClick={handleCloseModaldtaModal}
              >
                Later
              </Button> */}
                <Button
                  variant="contained"
                  color="grey"
                  style={{ color: "#fff", backgroundColor: "#199a8e" }}
                  onClick={handleProceedModaldta}
                >
                  Ok
                </Button>
              </div>
            </>
          )}
        </div>
      </Modal>
    </>
  );
};
export default Doctordetails;
