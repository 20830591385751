import React, { useState, useEffect, useContext } from "react";
import { PatientpageContext } from "../../context/GlobalContext";
import SwtichTool from "../../Tools/Swtich";

import {
  Grid,
  Box, Switch, Paper, IconButton
} from "@mui/material";


import CircularProgress from "@mui/material/CircularProgress";
import Overlay from "./../../components/Overlay";
import NonRecurring from "../../components/NonRecurring";
import RecurringForm from "../../components/RecurringForm";
import Tooltip from "@mui/material/Tooltip";
import Fade from "@mui/material/Fade";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import TooltipComponent from "../../components/TooltipComponent";
import { ServiceCall } from "../../utility/service-call";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useHistory, useLocation } from "react-router-dom";
import PageContentLayout from "../../components/PageContentLayout";


let weekDays = {
  0: {
    value: 0, name: 'Monday', selected: false
  },
  1: {
    value: 1, name: 'Tuesday', selected: false
  },
  2: {
    value: 2, name: 'Wednesday', selected: false
  },
  3: {
    value: 3, name: 'Thursday', selected: false
  },
  4: {
    value: 4, name: 'Friday', selected: false
  },
  5: {
    value: 5, name: 'Saturday', selected: false
  },
  6: {
    value: 6, name: 'Sunday', selected: false
  }
}
let timeArr = [
  {
    data: "12:00 AM",
    value: "24.00",
  },
  {
    data: "12:30 AM",
    value: "00.30",
  },
  {
    data: "01:00 AM",
    value: "01.00",
  },
  {
    data: "01:30 AM",
    value: "01.30",
  },
  {
    data: "02:00 AM",
    value: "02.00",
  },
  {
    data: "02:30 AM",
    value: "02.30",
  },
  {
    data: "03:00 AM",
    value: "03.00",
  },
  {
    data: "03:30 AM",
    value: "03.30",
  },
  {
    data: "04:00 AM",
    value: "04.00",
  },
  {
    data: "04:30 AM",
    value: "04.30",
  },
  {
    data: "05:00 AM",
    value: "05.00",
  },
  {
    data: "05:30 AM",
    value: "05.30",
  },
  {
    data: "06:30 AM",
    value: "06:30",
  },
  {
    data: "07:00 AM",
    value: "07.00",
  },
  {
    data: "07:30 AM",
    value: "07.30",
  },
  {
    data: "08:00 AM",
    value: "08.00",
  },
  {
    data: "08:00 AM",
    value: "08.00",
  },
  {
    data: "08:00 AM",
    value: "08.00",
  },
  {
    data: "08:30 AM",
    value: "08.30",
  },
  {
    data: "09:00 AM",
    value: "09.00",
  },
  {
    data: "09:30 AM",
    value: "09.30",
  },
  {
    data: "10:00 AM",
    value: "10.00",
  },
  {
    data: "10:30 AM",
    value: "10.30",
  },
  {
    data: "11:00 AM",
    value: "11.00",
  },
  {
    data: "11:30 AM",
    value: "11.30",
  },
  {
    data: "12:00 PM",
    value: "12.00",
  },
  {
    data: "12:30 PM",
    value: "12.30",
  },
  {
    data: "01:00 PM",
    value: "13.00",
  },
  {
    data: "01:30 PM",
    value: "13.30",
  },
  {
    data: "02:00 PM",
    value: "14.00",
  },
  {
    data: "02:30 PM",
    value: "14.30",
  },
  {
    data: "03:00 PM",
    value: "15.00",
  },
  {
    data: "03:30 PM",
    value: "15.30",
  },
  {
    data: "04:00 PM",
    value: "16.00",
  },
  {
    data: "04:30 PM",
    value: "16.30",
  },
  {
    data: "05:00 PM",
    value: "17.00",
  },
  {
    data: "05:30 PM",
    value: "17.30",
  },
  {
    data: "06:00 PM",
    value: "18.00",
  },
  {
    data: "06:30 PM",
    value: "18.30",
  },
  {
    data: "07:00 PM",
    value: "19.00",
  },
  {
    data: "07:30 PM",
    value: "19.30",
  },
  {
    data: "08:00 PM",
    value: "20.00",
  },
  {
    data: "08:30 PM",
    value: "20.30",
  },
  {
    data: "09:00 PM",
    value: "21.00",
  },
  {
    data: "09:30 PM",
    value: "21.30",
  },
  {
    data: "10:00 PM",
    value: "22.00",
  },
  {
    data: "10:30 PM",
    value: "22.30",
  },
  {
    data: "11:00 PM",
    value: "23.00",
  },
  {
    data: "11:30 PM",
    value: "23.30",
  },
];
export default function DoctorMyScheduleTab(props) {
  // const [isFilePicked, setIsFilePicked] = useState(false);
  const [appointmentLoader, setAppointmentLoader] = useState(false);
  // const [docAvailable, setDocAvailable] = useState(true);
  const [recurring, setRecurring] = useState(true);
  const history = useHistory();
  const [prevRoute, setPrevRoute] = useState("");

  const [open, setOpen] = useState(false);

  const doctorContext = useContext(PatientpageContext);

  useEffect(() => {
    if (doctorContext.doctorSchedule === 'loading') {
      // getDoctorsSchedule();
    } else {
      // updateAppointmentSlots(doctorContext.doctorSchedule);
    }
  }, [doctorContext]);




  const updateAppointmentLoader = function (flag) {
    setAppointmentLoader(flag)
  }

  useEffect(() => {
    // Set the previous route whenever the route changes
    setPrevRoute(history.location.pathname);
  }, [history.location.pathname]);

  const handlle = () => {
    let redirectTo = "/homie"; // Default redirect path
    
    // Check previous route
    if (prevRoute === "/doctor-btn") {
      redirectTo = "/doctor-btn";
    }

    history.push({
      pathname: redirectTo,
      search: history.location.search,
    });
  };

  const homedata = doctorContext.homedata;


  return (
    <>
                <PageContentLayout> 

      <Box
        // component={Paper}
        sx={{
          px: 3,
          my: 3,
          position: "relative",
          mx: "auto",
          top: "1%",
          position: "relative",
          flexGrow: 1,
          borderBottom:"0px",
          paddingLeft: "62px",
          marginTop: "-2rem",
        }}
      >
        
        {doctorContext.doctorScheduleLoader === "loading" ||
          (appointmentLoader && (
            <Overlay opacity=".6">
              <CircularProgress size="20px" color="success" />
            </Overlay>
          ))}
        <Grid container alignItems="center" spacing={2} sx={{marginTop: "50px"}}>
          <Grid item xs={12} className="text-start">
            <h3 className="mb-4" style={{fontSize:"12px"}}>
              Update today’s schedule and Save. Switch on “Update Recurring
              Schedule” to update daily running schedule.
            </h3>
            <label style={{display: "flex", alignItems: "center", justifyContent: "space-evenly", float: "inline-start", gap: "36px"}}>
              <label style={{ fontWeight: "bold" }}>
                Update Recurring Schedule
              </label>
              <SwtichTool
                checked={recurring}
                handleChange={(e) => setRecurring(e.target.checked)}
              />
            </label>
          </Grid>
          {recurring ? (
            <RecurringForm weekDays={weekDays} timeArr={timeArr} />
          ) : (
            <NonRecurring timeArr={timeArr} />
          )}
        </Grid>
      <Box sx={{height:"80px", width:"100"}}/>
      </Box>

      </PageContentLayout>
    </>
  );
}
